module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: '100 días de código', // Navigation and Site Title
  siteTitleAlt: '#100DaysOfCode | 100 días de código', // Alternative Site title for SEO
  siteTitleManifest: 'code',
  siteUrl: 'https://100.danielprimo.io', // Domain of your site. No trailing slash!
  siteLanguage: 'es', // Language Tag on <html> element
  siteHeadline: 'Diario de un reto de programación', // Headline for schema.org JSONLD
  siteDescription: 'Diario de un reto de programación.', // Your site description
  siteDescriptionLong: '<strong>100 días de código</strong>. <a href="/que-es-este-diario">Diario</a> de un reto de programación.',
  siteDescriptionButtonText: 'Ir a danielprimo.io',
  siteDescriptionButtonLink: 'https://www.danielprimo.io',
  siteBanner: '/social/banner.png', // Your image for og:image tag. You can find it in the /static folder
  favicon: 'src/favicon.png', // Your image for favicons. You can find it in the /src folder
  author: 'Daniel Primo', // Author for schemaORGJSONLD
  siteLogo: '/social/logo.png', // Image for schemaORGJSONLD
  cookieConsentButtonText: 'Estoy de acuerdo',
  cookieConsentMessageText: 'Este sitio utiliza cookies para mejorar la experiencia del usuario.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@delineas', // Twitter Username - Optional
  ogSiteName: 'delineas', // Facebook Site Name - Optional
  ogLanguage: 'es_ES', // Facebook Language
  //googleAnalyticsID: 'UA-269650-17',
  yandexMetrikaID: '45879696',
  disqusShortname: '100-danielprimo-io',

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c'
};
